<template>
  <div>
    <Header> 《用户授权协议》 </Header>
    <div class="jimai" v-html="this.anyInfo.agreement"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      anyInfo: '', //用户协议
    };
  },

  // 页面加载
  mounted() {
    this.$api.anyInfo().then((res) => {
      if (res.code == 0) {
        this.anyInfo = res.data;
      }
    });
  },

  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  background-color: #ffffff;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    margin-left: 28%;
  }
}

.jimai {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
